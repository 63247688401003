import courseService from "../../services/course.service"


    export const state ={
        courses:[],

    }
    export const actions = {
        getAcademicStructure({commit,}){
            return  courseService.getAcademicStructure().then(
                data => {
                    commit ('SET_ACADEMIC_STRUCTURE', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

    }
    export const mutations = {
        SET_CAMPUS(state, campus){
            state.campusses = campus
        },
    }
    export const getters = {
        get_schools(state){
            return state.schools
        },
        get_campus(state){
            return state.campusses
        },

    }


