import api from "../helpers/api";

class AdmissionService{
    async getAdmissions(){
        return await api.get(`applications`,{
            params:{admission:true}
        }).then(
            response => {
                return response.data
            }
        )}

    async getApplicants(){
        return await api.get(`applications`).then(
            response => {
                return response.data
            }
        )}

    async getApplicant(id){
        return await api.get(`application/${id}`).then(
            response => {
                return response.data
            }
        )}

    async validateApplicant(id,choice){
        return await api.post(`validate_applicant/${id}`,{
            choice:choice
        }).then(
            response => {
                return response.data
            }
        )}
    

    async searchApplicants(data){
        return await api.get(`searchApplicants`,{params:{
            campus_id:data.campus,
            department_id:data.department,
            program_id:data.program,
            school_id:data.school,
            academic_year_id:data.year
        }
        }).then(
            response => {
                return response.data
            }
        )}

    async searchAdmissions(data){
        return await api.get(`searchApplicants`,{
            params:{
                admission:true,
                campus_id:data.campus,
                department_id:data.department,
                program_id:data.program,
                school_id:data.school,
                academic_year_id:data.year
            }
        }).then(
            response => {
                return response.data
            }
        )}

    async getStats(data){
        
        return await api.get(`get_admission_stats`,{
            params:{
                admission:true,
                campus_id:data.campus,
                department_id:data.department,
                program_id:data.program,
                school_id:data.school,
                academic_year_id:data.year
            }
        }).then(
            response => {
                return response.data
            }
        )}
    async getCurrentStats(){
        
        return await api.get(`get_application_stats`).then(
            response => {
                return response.data
            }
        )}
}
export default new AdmissionService;