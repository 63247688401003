import { createI18n } from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const pathParts = key.split('/');

    // Ensure we have at least three parts: ['.', 'locale', 'filename']
    if (pathParts.length >= 3) {
      const locale = pathParts[1]; // This gets 'en' or 'fr'
      const fileContents = locales(key);

      if (!messages[locale]) {
        messages[locale] = {};
      }

      // Merge the file contents into the locale object
      Object.assign(messages[locale], fileContents);

      // Debug logging to check what is being loaded
      console.log(`Loaded messages for locale ${locale}:`, messages[locale]);
    }
  });

  return messages;
}

const setDateTimeFormats = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  },
};

const dateTimeFormats = {
  en: setDateTimeFormats,
  es: setDateTimeFormats,
  de: setDateTimeFormats,
};

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats,
});
