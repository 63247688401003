import userService from "../../helpers/authservice/user.service";

    export const state ={
        users:[],
        roles:[],
        permissions:[],
        

    }
    export const actions = {
        getUsers({commit,}){
            return  userService.getAllUsers().then(
                data => {
                    commit ('SET_USER', data.data);
                    // console.log(data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        // getProfile({commit}){
        //     // TBD Get user data and use that to get  current auth user 
        //     return  userService.verifyUser().then(
        //         data => {
        //             commit ('SET_USER', data.user);
        //             return Promise.resolve(data.user);
        //         }, error => {
        //             commit('AssetsFailed');
        //             return Promise.reject(error);
        //         }
        //     );
        // },
        getRoles({commit}){
            return userService.getRoles().then(
                data => {
                    commit ('SET_ROLES', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        getPermissions({commit}){
            return userService.getPermissions().then(
                data => {
                    commit ('SET_PERMISSIONS', data.permissions);
                    return Promise.resolve(data.permissions);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        
    }
    export const mutations = {
        // SET_ROLES(state, campus){
        //     state.campusses = campus
        // },
        SET_USER(state,users){
            state.users = users
            // console.log(state.users);
        },
        SET_ROLES(state,roles){
            state.roles = roles
        },
        SET_PERMISSIONS(state,permissions){
            state.permissions = permissions
        },
       
        AssetsFailed(){

        }
    }

    export const getters = {
        get_users(state){
            return state.users
        },
        
        get_roles(state){
            return state.roles
        },

        get_permissions(state){
            return state.permissions
        },
       
        getUserById: (state) => (id) => {
            let user =  state.users.find((user) => user.id == id);
            // console.log(state.users);
            return user
          },
    }


