import AdmissionService from "../../services/admission.service";
export const state = {
    admissions:[],
}

export const  actions = {  
    get({commit,}){ 
    return  AdmissionService.getAdmissions().then(
        response => {
            // console.log(data);
            commit ('SET_ADMISSIONS', response.data.data);
            return Promise.resolve(response.data);
        }, error => {
            commit('AssetsFailed');
            return Promise.reject(error);
        }
    );
    }
}
export const mutations = {
    SET_ADMISSIONS(state, admission){
        state.admissions = admission
    },
}
export const getters = {
    get_admissions(state){
        console.log(state.admisions);
        return state.admisions
    },

}


