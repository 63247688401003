import schoolService from "../../services/school.service"


    export const state ={
        schools:[],
        campusses:[],
        departments:[],
        programs:[],
        options:[],
        levels:[],
        years:[],
        structure:[]

    }
    export const actions = {
        getAcademicStructure({commit,}){
            return  schoolService.getAcademicStructure().then(
                data => {
                    commit ('SET_ACADEMIC_STRUCTURE', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

        getAcademicYears({commit,}){
            return  schoolService.getAcademicYear().then(
                data => {
                    commit ('SET_ACADEMIC_YEARS', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        getSchools({commit,}){
            return  schoolService.getSchools().then(
                data => {
                    commit ('SET_SCHOOLS', data.data.schools);
                    return Promise.resolve(data.schools);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        getCampusses({commit}){
            return schoolService.getCampusses().then(
                data => {
                    commit ('SET_CAMPUS', data.data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },
        getDepartments({commit}){
            return schoolService.getDepartments().then(
                data => {
                    commit ('SET_DEPARTMENTS', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

        getPrograms({commit}){
            return schoolService.getPrograms().then(
                data => {
                    commit ('SET_PROGRAMS', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

        getOptions({commit}){
            return schoolService.getOptions().then(
                data => {
                    // console.log(data.data.data);
                    commit ('SET_OPTIONS', data.data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        },

        getLevels({commit}){
            return schoolService.getLevels().then(
                data => {
                    commit ('SET_LEVELS', data.data);
                    return Promise.resolve(data.data);
                }, error => {
                    commit('AssetsFailed');
                    return Promise.reject(error);
                }
            );
        }
    }
    export const mutations = {
        SET_CAMPUS(state, campus){
            state.campusses = campus
        },
        SET_SCHOOLS(state,schools){
            state.schools = schools
        },
        SET_ACADEMIC_YEARS(state,years){
            state.years = years
        },
        SET_ACADEMIC_STRUCTURE(state,structure){
            state.structure = structure
        },
        SET_DEPARTMENTS(state, departments){
            state.departments = departments
        },
        SET_PROGRAMS(state, programs){
            state.programs = programs
        },
        SET_LEVELS(state, levels){
            state.levels = levels
        },
        AssetsFailed(){

        }
    }
    export const getters = {
        get_schools(state){
            return state.schools
        },
        get_campus(state){
            return state.campusses
        },
        get_departments(state){
            return state.departments
        },
        get_programs(state){
            // console.log(state.programs);
            return state.programs
        },
        get_options(state){
            console.log(state.programs);
            return state.programs
        },
        get_levels(state){
            return state.levels
        },
        get_years(state){
            return state.years
        },
        
        get_structure(state){
            return state.structure
        },

        get_academic_years(state){
            return state.years
        },

    }


