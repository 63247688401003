import api from "../helpers/api";

class CourseService{
    async getCourses(parameters){
        return await api.get(`courses`,{params:{
            department_id:parameters.department,
            program_id:parameters.program,
            level_id:parameters.level,
            semester_id:parameters.semester
        }}).then(
            response => {
                return response.data
            }
        )}
    async getCourse(id){
        return await api.get(`course/${id}`).then(
            response => {
                return response.data
            }
        )}

        async getCourseList(parameters){
            return await api.get(`student_course`,{params:{
                course_id:parameters.course_id,
                ay:parameters.ay_id,
                session:parameters.session
            }}).then(
                response => {
                    return response.data
                }
            )}
    
        async getStudentCourseList(parameters){
            return await api.get(`student_course_list`,{
                params: {
                    semester_id:parameters.semester_id,
                    say_id:parameters.say_id,
                    student_id:parameters.student_id,
                    session:parameters.session
                }
            }).then(
                response => {
                    return response.data
                }
            )}

    async editCourse(data){
        // console.log(data.credit_value);
        return await api.put(`course/${data.id}`,{
            department_id:data.department,
            program_id:data.program,
            level_id:data.level,
            title:data.title,
            course_code:data.course_code,
            course_type:data.course_type,
            credit_value:data.credit_value,
            description:data.description,
            duration:data.duration,
            semester_id:data.semester,
            short_code:data.short_code,
        }).then(
            response => {
                return response.data
            }
        )}
    async saveCourse(data){
        // console.log(data.credit_value);
        return await api.post(`course`,{
            department_id:data.department,
            program_id:data.program,
            level_id:data.level,
            title:data.title,
            course_code:data.course_code,
            course_type:data.course_type,
            credit_value:data.credit_value,
            description:data.description,
            duration:data.duration,
            semester_id:data.semester,
            short_code:data.short_code,
        }).then(
            response => {
                return response.data
            }
        )}


    async deactivateCourse(id,status){
        return await api.put(`/deactivate_course`,{
            id:id,
            status: status
        }).then(
            response => {
                return response
            }
        )
    }


    async destroyCourse(course_id){
        return await api.delete(`/course`,{
            params:{
                course_id:course_id,
            }
        }).then(
            response => {
                return response
            }
        )
    }

    // import courses in an excel file 
    async importCourses(data){
        return await api.post(`import-courses`,data).then(
            response => {
                return response.data.data;
            }
        )
    }

    // Upload Time Table in an pdf file 
    async uploadTimeTable(data){
        return await api.post(`upload-timetable`,data).then(
            response => {
                return response.data;
            }
        )
    }
    // Upload Time Table in an pdf file 
    async getTimeTable(data){
        return await api.get(`get-timetable`,data).then(
            response => {
                return response.data.data;
            }
        )
    }


    
    
}
export default new CourseService;