import { createStore } from 'vuex'
import modules from './modules'
// import admission from './modules/admission'
// import school from './modules/school'

// export default createStore ({
//   modules:{
//     admission,
//     school

//   }
// })

const store = createStore({
  modules
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  // strict: process.env.NODE_ENV !== 'production',
})

export default store

